import { onClickAnchorSmooth } from "../utils/nav-utils.ts";

export default function TraceCarouselIntroduction() {
  return (
    <section
      id="traces"
      className="flex flex-col justify-center items-center max-w-[76rem] mx-auto mt-24 p-8 text-center"
    >
      <h2 className="font-bold text-2xl md:text-4xl">See XBOW at work</h2>
      <p className="font-bold text-lg md:text-2xl !leading-normal mt-4 opacity-70 max-w-[93%] lg:max-w-[80%] xl:max-w-[65%]">
        XBOW pursues high-level goals by executing commands and reviewing their
        output, without any human intervention.
      </p>
      <p className="font-medium text-lg sm:text-2xl !leading-normal mt-4 opacity-50 max-w-[93%] lg:max-w-[90%] xl:max-w-[70%]">
        These are real examples of XBOW solving benchmarks. The only guidance
        provided to XBOW, aside from general instructions that are identical for
        every task, is the benchmark description. If you&apos;d like to see all
        the data,{" "}
        <a
          href="#faq-labs-and-exercises-details"
          className="text-xbow-electric-green underline"
          onClick={onClickAnchorSmooth}
        >
          click here
        </a>
        .
      </p>
    </section>
  );
}
