import clsx from "clsx";
import { useRef } from "react";
import { ScreenshotModeProvider } from "../hooks/useScreenshotMode.tsx";
import TraceCarousel from "./TraceCarousel.tsx";
import TraceCarouselIntroduction from "./TraceCarouselIntroduction.tsx";
import type { RenderedTrace } from "./types.ts";

interface Props {
  traces: Omit<RenderedTrace, "sections">[];
}

export default function TraceCarouselWrapper(props: Props) {
  const { traces } = props;
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <div id="TraceCarousel" className="overflow-hidden mb-24" ref={rootRef}>
      <TraceCarouselIntroduction />
      <ScreenshotModeProvider client:load>
        <section
          id="TraceCarousel--carousel"
          className={clsx(
            "max-w-[calc(100vw-1.25rem)] lg:max-w-[1200px] 2xl:max-w-screen-xl mx-auto px-9 md:px-15 pt-9 pb-24",
          )}
        >
          <TraceCarousel
            traces={traces}
            className="aspect-w-9 aspect-h-16 md:aspect-w-3 md:aspect-h-4 lg:aspect-w-16 lg:aspect-h-9"
            rootRef={rootRef}
          />
        </section>
      </ScreenshotModeProvider>
    </div>
  );
}
